/* .form-class{
    margin: 20rem;
    margin-top: 10rem;
}
button{
    margin-left:45% ;
}
.form-check-input:checked {
    background-color:#FFB800 ;
    border-color: #FFB800;
} */




html{
    font-size: 100%;
  }
  
  #loginform{
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 30%;
    border: 1px solid black;
    padding: 50px;
    height: 60%;
    margin: 55px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }
  #Queryform{
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 50%;
    padding: 50px;
    height: 60%;
    margin: 55px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }
  
  #partner{
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 30%;
    height: 60%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }
  
  #headerTitle{
    text-align: center;
    font-family: 'open sans', sans-serif;
    padding: 5px;
    margin: 0;

  }
  
  .row{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    max-width: 100%;
    
  }
  
  .row input{
    width: 100%;
    box-sizing: border-box;
      border: none;
    font-size: 1.3rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
    transition: box-shadow 0.2s ease-in;
  }
  
  .row input:focus{
     box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
     outline: none;
  }
  
  .row input::-webkit-input-placeholder{
    opacity: 1;
    transition: opacity 0.25s ease-out;
  }
  
  .row input:hover::-webkit-input-placeholder,
  .row input:focus::-webkit-input-placeholder
  .row textarea:focus::-webkit-input-placeholder{
    opacity: 0;
  }
  
  .row label{
    align-self: start;

    padding-bottom: 0.5rem;
    color: rgba(187,187,187,0.9);
  }
  
  .row button{
    border-radius: 25px;
    width: 80%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
   background-color: black;
    border: 0px;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
  }


  
  .row   textarea{
    width:100%;
    box-sizing: border-box;
    border: 3px solid #f1f1f1;
    color: #757575;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;

    transition: box-shadow 0.2s ease-in;
  }
  .row textarea:active{
    border: 3px solid rgba(34,193,195,0.7);
    text-decoration: none;
 }

  
  .row button:hover{
    opacity: 0.8;
  }
  
  #button{
    padding-bottom: 1.5rem;
  }
  
  #facebookIcon{
    background-image:      url("https://image.flaticon.com/icons/svg/145/145802.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #twitterIcon{
    background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #googleIcon{
    background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #iconGroup{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
  
  #iconGroup a{
    margin: 0 0.6rem;
  }
  
  #alternativeLogin {
     text-align: center;
     padding-top: 2rem;
     margin-top: 1.5rem;
  }
  .flexy{
    display: flex;
  }
  
  .otherm{
    color: blue;
    display: flex;
    justify-content: center;
    
  }
  .loginimg{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }