/* .form-class{
    margin: 20rem;
    margin-top: 10rem;
}
button{
    margin-left:45% ;
}
.form-check-input:checked {
    background-color:#FFB800 ;
    border-color: #FFB800;
} */




html{
    font-size: 100%;
  }
  
  #BussCont{
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    width: 80%;
    height: 60%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
  }
  

  

  
  .row-1{
    display: flex;
    flex-direction: column;

    padding-top: 2rem;
    max-width: 100%;
    
  }


th {
    border: 10px solid transparent;
}
  .row-1 input{
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
    transition: box-shadow 0.2s ease-in;
  }
  .row-1 select{
    width: 100%;
    box-sizing: border-box;
    border: none;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
    transition: box-shadow 0.2s ease-in;
  }
  .row-1 input:focus{
     box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
     outline: none;
  }
  
  .row-1 input::-webkit-input-placeholder{
    opacity: 1;
    transition: opacity 0.25s ease-out;
  }
  
  .row-1 input:hover::-webkit-input-placeholder,
  .row-1 input:focus::-webkit-input-placeholder{
    opacity: 0;
  }
  
  .row-1 label{
    align-self: start;
 
    padding-bottom: 0.5rem;
    color: rgba(187,187,187,0.9);
  }
  
  .row-1 button{
    border-radius: 25px;
    width: 100%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
   background-color: black;
    border: 0px;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
  }
  
  .row-1 button:hover{
    opacity: 0.8;
  }
  
  #button{
    padding-bottom: 1.5rem;
  }
  
  #facebookIcon{
    background-image:      url("https://image.flaticon.com/icons/svg/145/145802.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #twitterIcon{
    background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #googleIcon{
    background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
  }
  
  #iconGroup{
    display: flex;
    justify-content: center;
    padding: 2rem 0;
  }
  
  #iconGroup a{
    margin: 0 0.6rem;
  }
  
  #alternativeLogin {
     text-align: center;
     padding-top: 2rem;
     margin-top: 1.5rem;
  }
  .flexy{
    display: flex;
  }
  
  