/* html,
body {
  height: 100%;
} */
.contain video {
  width: 100%;
  height: auto;
}

.contain {
  position: relative;
  text-align: center;
  color: white;
}


.bottom-1 {
  display: flex;
  border: 1px solid rgb(0, 0, 0);
  padding-left: 61px;
  padding-right: 61px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5rem;
  background: #ffffffb0;
  align-items: center;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottomobile {
  display: flex;
    border: 1px solid rgb(0, 0, 0);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5rem;
    background: #ffffffb0;
    align-items: center;
    position: absolute;
    top: 85%;
    left: 10%;
    transform: translate(-10%, -70%);
}
.bottom-1 div a{

  text-align: left;
   text-decoration: none;
   color: rgb(0, 0, 0); 
}
.bottom-1 div{
  padding: 15px;
}

@media only screen and (max-width:700px)
{
  .bottom-1{
    display: none;
  }
  }
  @media (min-width:701px) and (max-width:5000px) {
    .bottomobile{
      display: none;
    }

  }