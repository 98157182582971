.navu{
background-color: white;
}
a{
    text-decoration: none;
    color: black;
}
@media only screen and (max-width:425px)
{
   .home img{
    width: 50%;
   }
   .navu {
    padding: 0;
   }
}