

header{
    max-width: 1200px;
    margin:40px auto;
    
}

.cardScrool {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin: auto;
    max-width: 90%;
    max-height: 450px;
    border: 1px solid #dee2e5;
    overflow-x: scroll;
    overscroll-behavior-y: none;
    scroll-behavior: smooth;
    cursor: pointer;
    justify-content: center;
}


/*  */

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 300px;
    height: auto;
    margin: 2rem;
    border-radius: 15px;
    display: flex;
    z-index: -1;
  }

  .card img{
    border-radius: 15px;
   width: 300px;
   height: 200px;
  }
  .card p{
    margin-bottom: 0;
  }
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .cont {
    padding: 2px 16px;
  }

  .destination-button{
    background-color: #e20718;
    color: white;
    border: none;
    border-radius: 2rem;
    margin-bottom: 5px;
    margin: 10px 0px 10px 0px;
  }
  @media only screen and (max-width:375px)
{
   .card{
    width: 80%;
   }
   .cardScrool{
    border: 1px solid #dee2e5;
    max-width: 284px;

    max-height: 300px;
    background: #e207180a;
    margin: auto;
   }
  
   h1{
    font-size: 6vw;
   }
}
@media (min-width:376px) and (max-width:500px) {
  .card{
    width: 80%;
   }
   .cardScrool{
    border: 1px solid #dee2e5;
    max-width: 365px;
    max-height: 348px;
    background: #e207180a;
    margin: auto;
   }
  
   h1{
    font-size: 6vw;
   }
}
@media (min-width:501px) and (max-width:550px) {
  .card{
    width: 80%;
   }
   .cardScrool{
    border: 1px solid #dee2e5;
    max-width: 500px;
    max-height: 348px;
    background: #e207180a;
    margin: auto;
   }
  
   h1{
    font-size: 6vw;
   }
   header{
    margin-top: 50px;
    margin-bottom: 20px;
    margin-left: 93px;
   }
}
@media (min-width:551px) and (max-width:768px) {
  .card{
    width: 30%;
   }
   .cardScrool{
    border: 1px solid #dee2e5;
    max-width:670px;
    max-height: 348px;
    background: #e207180a;
    margin: auto;
   }
  
   h1{
    font-size: 6vw;
   }
   header{
    margin-top: 50px;
    margin-bottom: 20px;
    margin-left: 93px;
   }

}