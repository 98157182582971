* {
    box-sizing: border-box;
  }
  
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  
  .flex-item-left {
    background-color: #000000;
    padding: 30px;
    color: white;
    flex: 50%;
    text-align: left;
  }
  
  .flex-item-right {
    background-color: rgb(0, 0, 0);
    padding: 2rem;
    color: white;
    flex: 50%;
    text-align: right;
  }
  .flex-item-right i{
   padding: 10px;
  }
  
  /* Responsive layout - makes a one column-layout instead of a two-column layout */
  @media (max-width: 800px) {
    .flex-item-right, .flex-item-left {
      flex: 100%;
    }
  }