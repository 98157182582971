/* html,
body {
  height: 100%;
} */
.contain video {
  width: 100%;
  height: auto;
}

.contain {
  position: relative;
  text-align: center;
  color: white;
}


.bottom-1 {
  display: flex;
  border: 1px solid white;
  padding-left: 61px;
  padding-right: 61px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5rem;
  background: #ffffff51;
  align-items: center;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom-1 div a{

  text-align: left;
   text-decoration: none;
   color: white; 
}
.bottom-1 div{
  padding: 15px;
}
.activity-cards{
  display: flex;
  justify-content: center;
  flex-wrap:wrap ;
}


/* activity-filter */
.activity-filter-main-box {
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  width: 70%;
  align-items: center;
  justify-content: space-around;
}
.acivity-filter-box td{
  padding-left: 10px;
  padding-right: 10px;
  text-align:center;
}
.acivity-filter-box {
border-right: 1px solid black;
}
.boxy{
  border: none;
}

.acivity-filter-box p{
  font-size: small;
  margin-bottom: 0;
}
.filter-main-container{
  display: flex;
  margin: 50px auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.filter-data-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 50%;
  text-align: justify;
}
.filter-data-img{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 30%;
}
.filter-data-img img{
  width: 380px;
}
