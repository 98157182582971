/* html,
body {
  height: 100%;
} */
h1{
  font-size: 2vw;
}
.contain video {
  width: 100%;
  height: auto;
}
.contain img{
  width: 100%;
  height: auto;
}
.contain {
  position: relative;
  text-align: center;
  color: white;
}
.top-left {
  position: absolute;
  top: 40%;
  left: 16px;
  font-size: 5vw;
}

.bottom {
  display:flex;
  border: 1px solid rgb(0, 0, 0);
  padding: 20px;
  border-radius: 5rem;
  background: #ffffffb0;
  align-items: center;
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bottom div a{
  padding: 10px;
   text-decoration: none;
   color: rgb(0, 0, 0); 
}

.home-icon {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-columns: repeat(
    auto-fit, minmax(200px, 1fr));
}
.home-icon i{
  text-align: center;

}
.home-icon-1{
  border: none;
  margin: auto;
}

@media only screen and (max-width:700px)
{
   .bottom{
    display: none;
   }
   .home-icon{
   
    margin-top: 2px;
   
   }

}
@media (min-width:551px) and (max-width:768px) {
  .bottom div a{
    padding: 5px;
    font-size: smaller;
  }
  .home-icon i{
    text-align: left;
    margin: 5px;
    padding: 2px;
    font-size: smaller;
  }

}
@media (max-width:768px) {
  .home-icon i{
    text-align: left;
    margin: 5px;
    padding: 2px;
    font-size: smaller;
  }
}


a:active {
  color: red;
  background-color: transparent;
}
a:hover{
  color: red;
  background-color: transparent;
}
a:focus{
  background-color: transparent;
}